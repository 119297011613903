const style = `
    #label {
        padding: 0.06125rem 0.5rem 0.06125rem 0.5rem;
        position: absolute;
        top: 0.3125rem;
        left: 0.3125rem;
        border-radius: 5px 0 5px 0;
        color: white;
        font-weight: 500;
        text-transform: uppercase;
        background: var(--cta-blue); 
        font-size: .75rem;
        white-space: no-wrap;
    }
`

const sheet = new CSSStyleSheet();

sheet.replace(style);

customElements.define(
    "survey-label",
    class extends HTMLElement {
        constructor() {
            super();

            let surveyLabelTemplate = document.createElement('template')

            surveyLabelTemplate.innerHTML = `
                <div id="label">
                </div>
            `

            let surveyLabelContent = surveyLabelTemplate.content;

            const shadowRoot = this.attachShadow({ mode: "open" });
            shadowRoot.appendChild(surveyLabelContent.cloneNode(true));
            shadowRoot.adoptedStyleSheets = [sheet];

            let label = shadowRoot.getRootNode().host.getAttribute('data-label');
            let style = shadowRoot.getRootNode().host.getAttribute('data-style') ? shadowRoot.getRootNode().host.getAttribute('data-style') : '';

            shadowRoot.getElementById("label").innerHTML = label;
            shadowRoot.getElementById("label").style = style;
        }
    },
);